import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Propietario } from '../_models/propietario.model';
import { registerUser } from '../../auth';

const userToken = localStorage.getItem(environment.authTokenKey);
const httpHeaders = {'Content-Type': 'text/plain'};

@Injectable({
   providedIn: 'root'
})

// --------------------------------------------------------------
// GeneralService es para uso exclusivo de usuarios autenticados
// --------------------------------------------------------------

export class GeneralService {

   constructor(private http: HttpClient) {}

// ----------------------------------------
// FUNCIONES PARA DASHBOARD
// ----------------------------------------

   Dashboard(): Observable<any> {
      return this.http.get<any>(environment.API_URL + 'dashboard?token=' + userToken);
   }

// ----------------------------------------
// FUNCIONES PARA PERFIL
// ----------------------------------------

   PerfilLeer(): Observable<any> {
      const ruta = environment.API_URL +  'perfil?token=' + userToken;
      return this.http.get<any>(ruta, { headers: httpHeaders});
   }

   // Éste método debe devolver un objeto de tipo registerUser
   PerfilActualizar(data: registerUser): Observable<any> {
      return this.http.post(environment.API_URL + 'perfil?token=' + userToken, data, { headers: httpHeaders });
   }

// ----------------------------------------
// FUNCIONES PARA ACTIVIDAD ECONÓMICA
// ----------------------------------------

   ActividadEconomicaLeer(): Observable<any> {
      const ruta = environment.API_URL + 'actividad-economica?token=' + userToken;
      return this.http.get<any>(ruta, { headers: httpHeaders});
   }

// ----------------------------------------
// FUNCIONES PARA DECLARACIÓN Y LIQUIDACIÓN
// ----------------------------------------

   DeclaracLiquidacLeer(tipo: string): Observable<any> {
      const ruta = environment.API_URL + 'declaracion-liquidacion/' + tipo + '?token=' + userToken + '&tipo=' + tipo;
      return this.http.get<any>(ruta, { headers: httpHeaders});
   }

   DeclaracLiquidacDeclarar(tipo: string, data): Observable<any> {
      const ruta = environment.API_URL + 'declaracion-liquidacion/' + tipo + '/declarar?token=' + userToken;
      return this.http.post(ruta, data, { headers: httpHeaders });
   }

   DeclaracLiquidacSubir(file: any, tipo: string, archivo: string): Observable<any> {
      const ruta = environment.API_URL + 'declaracion-liquidacion/act/subir?token=' + userToken + '&tipo=' + tipo + '&archivo=' + archivo;
      const formData: FormData = new FormData();
      formData.append(archivo, file);
      return this.http.post(ruta, formData);
   }

   DeclaracLiquidacEliminar(data): Observable<any> {
      const ruta = environment.API_URL + 'declaracion-liquidacion/act/eliminar-archivo?token=' + userToken;
      return this.http.post(ruta, data, { headers: httpHeaders });
   }

   DeclaracLiquidacAnular(data): Observable<any> {
      const ruta = environment.API_URL + 'declaracion-liquidacion/anular?token=' + userToken;
      return this.http.post(ruta, data, { headers: httpHeaders });
   }

   DeclaracLiquidacRecibo(tipo: string, recibo: string): Observable<any> {
      const ruta = environment.API_URL + 'declaracion-liquidacion/' + tipo + '/recibo?token=' + userToken + '&recibo=' + recibo;
      return this.http.get<any>(ruta, { headers: httpHeaders});
   }

// ----------------------------------------
// FUNCIONES PARA PROPIETARIO
// ----------------------------------------

   // Éste método debe devolver un objeto de tipo Propietario
   PropietarioLeer(): Observable<any> {
      const ruta = environment.API_URL + 'propietario?token=' + userToken;
      // console.log('general.service -> ruta: ' + ruta);
      return this.http.get<any>(ruta, { headers: httpHeaders});
   }



   // Éste método debe devolver un objeto de tipo Boolean
   PropietarioActualizar(data: Propietario): Observable<any> {
      return this.http.post(environment.API_URL + 'propietario?token=' + userToken, data, { headers: httpHeaders });
   }

// ----------------------------------------
// FUNCIONES PARA REPRESENTANTE LEGAL
// ----------------------------------------

   // Éste método debe devolver un objeto de tipo Propietario
   RepresentanteLegalLeer(): Observable<any> {
      const ruta = environment.API_URL + 'representante-legal?token=' + userToken;
      // console.log('general.service -> ruta: ' + ruta);
      return this.http.get<any>(ruta, { headers: httpHeaders});
   }

   // Éste método debe devolver un objeto de tipo Boolean
   RepresentanteLegalActualizar(data: Propietario): Observable<any> {
      return this.http.post(environment.API_URL + 'representante-legal?token=' + userToken, data, { headers: httpHeaders });
   }

// ----------------------------------------
// FUNCIONES PARA PAGOS
// ----------------------------------------

   PagosLeer(tipo: string, recibo?: string): Observable<any> {
      const ruta = environment.API_URL + `pagos/${tipo}?token=${userToken}&recibo=${recibo}`;
      return this.http.get<any>(ruta, { headers: httpHeaders});
   }

   PagosAgregar(tipo: string, data): Observable<any> {
      return this.http.post(environment.API_URL + `pagos/${tipo}?token=${userToken}`, data, { headers: httpHeaders });
   }

   PagosSubir(file: any, idr: string): Observable<any> {
      const ruta = environment.API_URL + 'pagos/subir?token=' + userToken + '&idr=' + idr;
      const formData: FormData = new FormData();
      formData.append('comprobante', file);
      return this.http.post(ruta, formData);
   }

// ----------------------------------------
// FUNCIONES PARA BANCO
// ----------------------------------------

   BancoLeer(): Observable<any> {
      const ruta = environment.API_URL + 'banco?token=' + userToken;
      return this.http.get<any>(ruta, { headers: httpHeaders});
   }

   BancoGuardar(data): Observable<any> {
      return this.http.post(environment.API_URL + 'banco?token=' + userToken, data, { headers: httpHeaders });
   }

// ----------------------------------------
// FUNCIONES PARA VEHÍCULOS
// ----------------------------------------

ActivEconomBuscarContrib(termino1: string, termino2: number): Observable<any> {
   return this.http.get<any>(environment.API_URL + 'actividades-economicas/buscar/contribuyente?token=' + userToken + '&termino1=' + termino1 + '&termino2=' + termino2 );
}
Uniddepmov(termino1: string, termino2: number): Observable<any> {
   return this.http.get<any>(environment.API_URL + 'movimate/buscar/movimate?token=' + userToken + '&termino1=' + termino1 + '&termino2=' + termino2 );
}
// ActivEconomBuscarActivPorContrib(id: string): Observable<any> {
//    return this.http.get<any>(environment.API_URL + 'actividades-economicas/buscar/actividades-contribuyente?token=' + userToken + '&id=' + id);
// }


// ActivEconomGuardarActiv(data: any): Observable<any> {
//    const ruta = environment.API_URL + 'actividades-economicas/guardar/actividad?token=' + userToken;
//    return this.http.post<any>(ruta, data, { headers: httpHeaders });
// }
ActivEconomGuardarActiv(data: any): Observable<any> {
   // return this.http.post(environment.API_URL + 'regsquims?token=' + userToken, data, { headers: httpHeaders });
   const ruta = environment.API_URL + 'actividades-economicas/guardar/actividad?token=' + userToken;
   return this.http.post<any>(ruta, data, { headers: httpHeaders });
}
ArmasGuardarAsig(data: any): Observable<any> {
   // return this.http.post(environment.API_URL + 'regsquims?token=' + userToken, data, { headers: httpHeaders });
   const ruta = environment.API_URL + 'asignacion-componentes/guardar/actividad?token=' + userToken;
   return this.http.post<any>(ruta, data, { headers: httpHeaders });
}
ArmasGuardarPorte(data: any): Observable<any> {
   const ruta = environment.API_URL + 'porte?token=' + userToken;
   return this.http.post<any>(ruta, data, { headers: httpHeaders });
}
ArmasGuardarBali(data: any): Observable<any> {
   // return this.http.post(environment.API_URL + 'regsquims?token=' + userToken, data, { headers: httpHeaders });
   const ruta = environment.API_URL + 'regbalis?token=' + userToken;
   return this.http.post<any>(ruta, data, { headers: httpHeaders });
}
InmuebleImagenSubir(control: string, file: any /* ,idi: string*/): Observable<any> {
   const ruta = environment.API_URL + 'inmueble/imagen/subir?token=' + userToken + /*'&idi=' + idi +*/ '&control=' + control;
   const formData: FormData = new FormData();
   formData.append(control, file);
   return this.http.post(ruta, formData);
}
// imgLateralSubir(control: string, file: any, idi: string): Observable<any> {
//    const ruta = environment.API_URL + 'inmueble/imagen/subir?token=' + userToken + '&idi=' + idi + '&control=' + control;
//    const formData: FormData = new FormData();
//    formData.append(control, file);
//    return this.http.post(ruta, formData);
// }
ArmasBuscarpasig(termino1: string, termino2: number ): Observable<any> {
   return this.http.get<any>(environment.API_URL + 'asignacion-componentes/buscar/armas?token=' + userToken + '&termino1=' + termino1 + '&termino2=' + termino2 );
}
BuscarArmasregbal(termino1: string, termino2: number ): Observable<any> {
   return this.http.get<any>(environment.API_URL + 'asignacion-componentes/buscar/regbal?token=' + userToken + '&termino1=' + termino1 + '&termino2=' + termino2 );
}
ArmasBuscarEncaut(termino1: string, termino2: number ): Observable<any> {
   return this.http.get<any>(environment.API_URL + 'asignacion-componentes/buscar/armasencaut?token=' + userToken + '&termino1=' + termino1 + '&termino2=' + termino2 );
}
RegsquimGuardar(data): Observable<any> {
   return this.http.post(environment.API_URL + 'regsquims?token=' + userToken, data, { headers: httpHeaders });
}
ArmasGuardarEncaut(data): Observable<any> {
   return this.http.post(environment.API_URL + 'guardarencaut?token=' + userToken, data, { headers: httpHeaders });
}
   RegsarmasLeer(): Observable<any> {
      const ruta = environment.API_URL + 'regsarme?token=' + userToken;
      return this.http.get<any>(ruta, { headers: httpHeaders});
   }

   RegsarmasGuardar(data): Observable<any> {
      return this.http.post(environment.API_URL + 'regsarme?token=' + userToken, data, { headers: httpHeaders });
   }
   RegsmunGuardar(data): Observable<any> {
      return this.http.post(environment.API_URL + 'regsmuns?token=' + userToken, data, { headers: httpHeaders });
   }
  unidepGuardar(data): Observable<any> {
      return this.http.post(environment.API_URL + 'unidepa?token=' + userToken, data, { headers: httpHeaders });
   }
   unidepLeer(): Observable<any> {
      const ruta = environment.API_URL + 'unidepa?token=' + userToken;
      return this.http.get<any>(ruta, { headers: httpHeaders});
   }
    EstadisticaMensualLeer() {
      return this.http.get<any>(environment.API_URL + 'estadistica-mensual?token=' + userToken);
   }

   id_unidep(): Observable<any> {
      const ruta = environment.API_URL + 'id_unidep?token=' + userToken;
      return this.http.get<any>(ruta, { headers: httpHeaders});
   }
   
   RegsmunLeer(): Observable<any> {
      const ruta = environment.API_URL + 'regsmuns?token=' + userToken;
      return this.http.get<any>(ruta, { headers: httpHeaders});
   }
   
   RegsquimLeer(): Observable<any> {
      const ruta = environment.API_URL + 'regsquims?token=' + userToken;
      return this.http.get<any>(ruta, { headers: httpHeaders});
   }
   RegsexploGuardar(data): Observable<any> {
      return this.http.post(environment.API_URL + 'regsexplos?token=' + userToken, data, { headers: httpHeaders });
   }
   RegsexploLeer(): Observable<any> {
      const ruta = environment.API_URL + 'regsexplos?token=' + userToken;
      return this.http.get<any>(ruta, { headers: httpHeaders});
   }

// ----------------------------------------
// FUNCIONES PARA PUBLICIDAD
// ----------------------------------------

   PublicidadLeer(): Observable<any> {
      const ruta = environment.API_URL + 'publicidad?token=' + userToken;
      return this.http.get<any>(ruta, { headers: httpHeaders});
   }

   PublicidadGuardar(data): Observable<any> {
      return this.http.post(environment.API_URL + 'publicidad?token=' + userToken, data, { headers: httpHeaders });
   }

// ----------------------------------------
// FUNCIONES PARA CUPONES
// ----------------------------------------

   Cupones(): Observable<any> {
      const ruta = environment.API_URL + 'cupones?token=' + userToken;
      return this.http.get<any>(ruta, { headers: httpHeaders});
   }
}