// Angular
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// RxJS
import { finalize, takeUntil, tap } from 'rxjs/operators';
// NGRX
import { Store } from '@ngrx/store';
import { AppState } from '../../../../core/reducers';
// Auth
import { AuthNoticeService, AuthService, Register, registerUser } from '../../../../core/auth/';
import { Subject } from 'rxjs';
import { ConfirmPasswordValidator } from './confirm-password.validator';

import { ConsultasService } from '../../../../core/daex';

@Component({
	selector: 'kt-register',
	templateUrl: './register.component.html',
	encapsulation: ViewEncapsulation.None
})
export class RegisterComponent implements OnInit, OnDestroy {
	registerFormNat: FormGroup;
	registerFormJur: FormGroup;

	formulario = true; // true = Natural, false = Jurídico
	loading = false;
	error = {
		patente: false,
		cedula: false,
		usuarioN: false,
		usuarioJ: false,
		pregSegN: false,
		pregSegJ: false
	};
	rif = [
		{value: 'V', view: 'V'},
		{value: 'J', view: 'J'},
		{value: 'E', view: 'E'},
		{value: 'G', view: 'G'},
		{value: 'P', view: 'P'},
		{value: 'C', view: 'C'}
	];
	nacionalidad = [
		{value: 'V', view: 'V'},
		{value: 'E', view: 'E'}
	];
	municipios = [];
	parroquias = [];
	sectores = [];

	private unsubscribe: Subject<any>; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

	/**
	 * Component constructor
	 *
	 * @param authNoticeService: AuthNoticeService
	 * @param router: Router
	 * @param auth: AuthService
	 * @param store: Store<AppState>
	 * @param fb: FormBuilder
	 * @param cdr
	 */
	constructor(
		private authNoticeService: AuthNoticeService,
		private router: Router,
		private auth: AuthService,
		private store: Store<AppState>,
		private fb: FormBuilder,
		private cdr: ChangeDetectorRef,
		private consulta: ConsultasService
	) {
		this.unsubscribe = new Subject();
	}

	ngOnInit() {
		this.initRegisterForm();
	}

	ngOnDestroy(): void {
		this.unsubscribe.next();
		this.unsubscribe.complete();
		this.loading = false;
	}

	/**
	 * Initalización de los formularios
	 * Parametros por defecto y validadores
	 */

	initRegisterForm() {
		// Se inicializan las variables de las lista Select
		this.consulta.listadoSelect('municipios').subscribe(d => {
			this.municipios = d;
		});

		// Se inicializan los campos de los formularios
		this.registerFormNat = this.fb.group({
			nacionalidad: ['', Validators.compose([
				Validators.required
			])],
			cedula: ['', Validators.compose([
				Validators.required,
				Validators.pattern('[0-9]*'),
				Validators.maxLength(8)
			])],
			tipo_rif: ['', Validators.compose([
				Validators.required
			])],
			rif: ['', Validators.compose([
				Validators.required,
				Validators.pattern('[0-9]*'),
				Validators.minLength(9),
				Validators.maxLength(9)
			])],
			nombre1: ['', Validators.compose([
				Validators.required,
				Validators.maxLength(20)
			])],
			nombre2: ['', Validators.compose([
				Validators.maxLength(20)
			])],
			apellido1: ['', Validators.compose([
				Validators.required,
				Validators.maxLength(20)
			])],
			apellido2: ['', Validators.compose([
				Validators.maxLength(20)
			])],
			municipio: ['', Validators.compose([
				Validators.required
			])],
			parroquia: ['', Validators.compose([
				Validators.required
			])],
			sector: ['', Validators.compose([
				Validators.required
			])],
			direccion: ['', Validators.compose([
				Validators.required,
				Validators.minLength(10),
				Validators.maxLength(150)
			])],
			telLoc: ['', Validators.compose([
				Validators.pattern('[0-9]*'),
				Validators.minLength(11),
				Validators.maxLength(11)
			])],
			telCel: ['', Validators.compose([
				Validators.required,
				Validators.pattern('[0-9]*'),
				Validators.minLength(11),
				Validators.maxLength(11)
			])],
			pregSeg1: ['', Validators.compose([
				Validators.required,
				Validators.minLength(10),
				Validators.maxLength(50)
			])],
			respSeg1: ['', Validators.compose([
				Validators.required,
				Validators.minLength(3),
				Validators.maxLength(15)
			])],
			pregSeg2: ['', Validators.compose([
				Validators.required,
				Validators.minLength(10),
				Validators.maxLength(50)
			])],
			respSeg2: ['', Validators.compose([
				Validators.required,
				Validators.minLength(3),
				Validators.maxLength(15)
			])],
			correo: ['', Validators.compose([
				Validators.required,
				Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$'),
				Validators.maxLength(100)
			])],
			usuario: ['', Validators.compose([
				Validators.required,
				Validators.minLength(5),
				Validators.maxLength(15)
			])],
			password: ['', Validators.compose([
				Validators.required,
				Validators.minLength(5),
				Validators.maxLength(15)
			])],
			confirmPassword: ['', Validators.compose([
				Validators.required,
			])],
			term_cond: [false, Validators.compose([Validators.required])]
		}, {
			validator: ConfirmPasswordValidator.MatchPassword
		});
		this.registerFormJur = this.fb.group({
			patente: ['', Validators.compose([
				Validators.maxLength(10)
			])],
			tipo_rif: ['', Validators.compose([
				Validators.required
			])],
			rif: ['', Validators.compose([
				Validators.required,
				Validators.pattern('[0-9]*'),
				Validators.minLength(9),
				Validators.maxLength(9)
			])],
			razonSoc: ['', Validators.compose([
				Validators.required,
				Validators.minLength(5),
				Validators.maxLength(150)
			])],
			denomCom: ['', Validators.compose([
				Validators.required,
				Validators.minLength(5),
				Validators.maxLength(150)
			])],
			municipio: ['', Validators.compose([
				Validators.required
			])],
			parroquia: ['', Validators.compose([
				Validators.required
			])],
			sector: ['', Validators.compose([
				Validators.required
			])],
			direccion: ['', Validators.compose([
				Validators.required,
				Validators.minLength(10),
				Validators.maxLength(150)
			])],
			telLoc: ['', Validators.compose([
				Validators.pattern('[0-9]*'),
				Validators.minLength(11),
				Validators.maxLength(11)
			])],
			telCel: ['', Validators.compose([
				Validators.required,
				Validators.pattern('[0-9]*'),
				Validators.minLength(11),
				Validators.maxLength(11)
			])],
			pregSeg1: ['', Validators.compose([
				Validators.required,
				Validators.minLength(10),
				Validators.maxLength(50)
			])],
			respSeg1: ['', Validators.compose([
				Validators.required,
				Validators.minLength(3),
				Validators.maxLength(15)
			])],
			pregSeg2: ['', Validators.compose([
				Validators.required,
				Validators.minLength(10),
				Validators.maxLength(50)
			])],
			respSeg2: ['', Validators.compose([
				Validators.required,
				Validators.minLength(3),
				Validators.maxLength(15)
			])],
			correo: ['', Validators.compose([
				Validators.required,
				Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$'),
				Validators.maxLength(100)
			])],
			usuario: ['', Validators.compose([
				Validators.required,
				Validators.minLength(5),
				Validators.maxLength(15)
			])],
			password: ['', Validators.compose([
				Validators.required,
				Validators.minLength(5),
				Validators.maxLength(15)
			])],
			confirmPassword: ['', Validators.compose([
				Validators.required,
			])],
			term_cond: [false, Validators.compose([Validators.required])]
		}, {
			validator: ConfirmPasswordValidator.MatchPassword
		});
	}

	//  Se ejecuta cuando hay algún cambio en el select Municipio
	buscarParroquias() {
		const controls = this.formulario ? this.registerFormNat.controls : this.registerFormJur.controls;
		this.consulta.listadoSelect('parroquias', controls.municipio.value).subscribe(d => this.parroquias = d);
	}
	//  Se ejecuta cuando hay algún cambio en el select Parroquia
	buscarSectores() {
		const controls = this.formulario ? this.registerFormNat.controls : this.registerFormJur.controls;
		this.consulta.listadoSelect('sectores', controls.parroquia.value).subscribe(d => this.sectores = d);
	}

	// Se ejecuta al enviar el formulario
	submit() {

		const controls = this.formulario ? this.registerFormNat.controls:this.registerFormJur.controls;
		const formInvalid = this.formulario ? this.registerFormNat.invalid:this.registerFormJur.invalid;
		// check form
		if (formInvalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
			return;
		}

		this.loading = true;

		if (!controls.term_cond.value) {
			// you must agree the terms and condition
			// checkbox cannot work inside mat-form-field https://github.com/angular/material2/issues/7891
			this.authNoticeService.setNotice('Debes aceptar los terminos y condiciones', 'danger');
			return;
		}
		const newUser: registerUser = new registerUser();
		newUser.clear();
		if (this.formulario) {
			newUser.nacionalidad = controls.nacionalidad.value;
			newUser.cedula = controls.cedula.value;
			newUser.nombre1 = controls.nombre1.value;
			newUser.nombre2 = controls.nombre2.value;
			newUser.apellido1 = controls.apellido1.value;
			newUser.apellido2 = controls.apellido2.value;
			newUser.type = 'N';
		} else {
			newUser.patente = controls.patente.value;
			newUser.razonSoc = controls.razonSoc.value;
			newUser.denomCom = controls.denomCom.value;
			newUser.type = 'J';
		}
		newUser.tipo_rif = controls.tipo_rif.value;
		newUser.rif = controls.rif.value;
		newUser.municipio = controls.municipio.value;
		newUser.parroquia = controls.parroquia.value;
		newUser.sector = controls.sector.value;
		newUser.direccion = controls.direccion.value;
		newUser.telLoc = controls.telLoc.value;
		newUser.telCel = controls.telCel.value;
		newUser.pregSeg1 = controls.pregSeg1.value;
		newUser.respSeg1 = controls.respSeg1.value;
		newUser.pregSeg2 = controls.pregSeg2.value;
		newUser.respSeg2 = controls.respSeg2.value;
		newUser.correo = controls.correo.value;
		newUser.usuario = controls.usuario.value;
		newUser.password = controls.password.value;

		this.auth.register(newUser).pipe(
			tap(user => {
				if (!user.err) {
					this.store.dispatch(new Register({authToken: user.accessToken}));
					// pass notice message to the login page
					this.authNoticeService.setNotice(user.msg, 'success');
					this.router.navigateByUrl('/entrar');
				} else {
					this.authNoticeService.setNotice(user.msg, 'danger');
				}
			}),
			takeUntil(this.unsubscribe),
			finalize(() => {
				this.loading = false;
				this.cdr.markForCheck();
			})
		).subscribe();
	}

	/**
	 * Validaciones de los controles
	 *
	 * @param controlName: string => Equals to formControlName
	 * @param validationType: string => Equals to valitors name
	 */
	isControlHasError(controlName: string, validationType: string): boolean {
		const control = this.formulario ? this.registerFormNat.controls[controlName] : this.registerFormJur.controls[controlName];
		if (!control) {
			return false;
		}

		const result = control.hasError(validationType) && (control.dirty || control.touched);
		return result;
	}

	existe(controlName: string) {
		if (controlName === 'cedula' || controlName === 'patente') {
			if (this.isControlHasError(controlName, 'required')) return false;
			if (this.isControlHasError(controlName, 'maxlength')) return false;
			if (this.isControlHasError(controlName, 'pattern')) return false;
		}
		const control = this.formulario ? this.registerFormNat.controls[controlName] : this.registerFormJur.controls[controlName];
		if (!control) return false;
		if (control.dirty || control.touched) {
			this.consulta.existe(controlName, control.value).pipe(
				tap(r => {
					if (this.formulario) {
						switch (controlName) {
							case 'cedula': this.error.cedula = r; break;
							case 'usuario': this.error.usuarioN = r; break;
						}
					} else {
						switch (controlName) {
							case 'patente': this.error.patente = r; break;
							case 'usuario': this.error.usuarioJ = r; break;
						}
					}
					if (r) {
						control.setErrors({incorrect: true});
					} else {
						const v = control.value;
						control.reset();
						control.setValue(v);
					}
				}),
				finalize(() => this.cdr.markForCheck())
			).subscribe();
		}
	}

	preguntas() {
		const pregSeg1 = this.formulario ? this.registerFormNat.controls.pregSeg1 : this.registerFormJur.controls.pregSeg1;
		const pregSeg2 = this.formulario ? this.registerFormNat.controls.pregSeg2 : this.registerFormJur.controls.pregSeg2;

		if (!pregSeg1 || !pregSeg2 || (pregSeg1.value !== '' && pregSeg2.value === '')) {
			return false;
		}

		const result = pregSeg1.value === pregSeg2.value && (pregSeg2.dirty || pregSeg2.touched);

		if (this.formulario) {
			this.error.pregSegN = result;
		} else {
			this.error.pregSegJ = result;
		}
		if (result) {
			pregSeg2.setErrors({incorrect: true});
		} else {
			const v = pregSeg2.value;
			pregSeg2.reset();
			pregSeg2.setValue(v);
		}
	}
}
