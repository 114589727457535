import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable} from 'rxjs';

const userToken = localStorage.getItem(environment.authTokenKey);
const httpHeaders = {'Content-Type': 'text/plain'};

@Injectable({
   providedIn: 'root'
})

// ---------------------------------------------------------------
// GeneralService es para uso exclusivo de empleados autenticados
// ---------------------------------------------------------------

export class DialogosService {

   constructor(
      private http: HttpClient
      ) {}

// ----------------------------------------
// FUNCIONES PARA LOS DIALOGOS
// ----------------------------------------

   DialogInmConfigPago(data): Observable<any> {
      const ruta = environment.API_URL + 'dialogos/inmueble/configuracion-pago?token=' + userToken;
      return this.http.post(ruta, data, { headers: httpHeaders });
   }

   DialogDecLiqSinCancelar(): Observable<any> {
      const ruta = environment.API_URL + 'dialogos/declaracion-liquidacion/sin-cancelar?token=' + userToken;
      return this.http.get(ruta, { headers: httpHeaders });
   }

   DialogCuponCanjear(data): Observable<any> {
      const ruta = environment.API_URL + 'dialogos/cupon/canjear?token=' + userToken;
      return this.http.post(ruta, data, { headers: httpHeaders });
   }
}
