// Angular
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
// RxJS
import { finalize, tap } from 'rxjs/operators';
// Auth
import { AuthNoticeService, AuthService } from '../../../../core/auth';

@Component({
    selector: 'confirmar',
    templateUrl: './confirmar.component.html',
    encapsulation: ViewEncapsulation.None
})
export class ConfirmarComponent implements OnInit {
    loading = false;

    constructor(
        private authService: AuthService,
        public authNoticeService: AuthNoticeService,
        private rutaActiva: ActivatedRoute,
        private router: Router
    ) {}

    ngOnInit() {
        this.authNoticeService.setNotice('Confirmando...', 'success');
        this.authService.confirmar(this.rutaActiva.snapshot.params.usuario).pipe(
            tap(r => {
                if (!r.err) {
                    this.authNoticeService.setNotice(r.msg, 'success');
                } else {
                    this.authNoticeService.setNotice(r.msg, 'danger');
                }
                this.router.navigateByUrl('/entrar');
            }),
            finalize(() => {
                this.loading = false;
            })
        ).subscribe();
    }
}
