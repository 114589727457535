// Angular
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
// RxJS
import { finalize, takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
// Auth
import { AuthNoticeService, AuthService } from '../../../../core/auth';
import { ConfirmPasswordValidator } from '../register/confirm-password.validator';

@Component({
    selector: 'kt-forgot-password',
    templateUrl: './forgot-password.component.html',
    encapsulation: ViewEncapsulation.None
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
    // Public params
    forgotPasswordForm: FormGroup;
    changePasswordForm: FormGroup;
    cargando = false;
    Mode: boolean = undefined;  // 0 = Recuperar, 1 = Cambiar
    errors: any = [];

    private unsubscribe: Subject<any>; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

    /**
     * Component constructor
     *
     * @param authService
     * @param authNoticeService
     * @param translate
     * @param router
     * @param fb
     * @param cdr
     */
    constructor(
        private authService: AuthService,
        public authNoticeService: AuthNoticeService,
        private router: Router,
        private fb: FormBuilder,
        private rutaActiva: ActivatedRoute,
        private cdr: ChangeDetectorRef
    ) {
        this.unsubscribe = new Subject();
    }

    ngOnInit() {
        this.initRegistrationForm();
        // console.log('Ruta activa: '+this.rutaActiva.snapshot.params.usuario);
        if (this.rutaActiva.snapshot.params.usuario) {
            this.authService.requestPasswordVerify(this.rutaActiva.snapshot.params.usuario).pipe(
                tap(r => {
                    if (!r.err) {
                        // this.authNoticeService.setNotice(r.msg, r.err ? 'danger' : 'success');
                        this.initChangePassForm();
                        this.Mode = true;
                    } else {
                        this.Mode = false;
                    }
                }),
                finalize(() => {
                    this.cargando = false;
                    this.cdr.markForCheck();
                })
            ).subscribe();
        } else {
            this.Mode = false;
        }
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.cargando = false;
    }

    initRegistrationForm() {
        this.forgotPasswordForm = this.fb.group({
            email: ['', Validators.compose([
                Validators.required,
                Validators.email,
                Validators.minLength(3),
                Validators.maxLength(200)
            ])]
        });
    }

    initChangePassForm() {
        this.changePasswordForm = this.fb.group({
            password: ['', Validators.compose([
                Validators.required,
                Validators.minLength(5),
                Validators.maxLength(15)
            ])],
            confirmPassword: ['', Validators.compose([
                Validators.required
            ])]
        }, {
            validator: ConfirmPasswordValidator.MatchPassword
        });
    }

    submit() {
        const controls = this.forgotPasswordForm.controls;
        /** check form */
        if (this.forgotPasswordForm.invalid) {
            Object.keys(controls).forEach(controlName =>
                controls[controlName].markAsTouched()
            );
            return;
        }

        this.cargando = true;

        const email = controls.email.value;
        // console.log(email);
        this.authService.requestPassword(email).pipe(
            tap(response => {
                if (response) {
                    this.authNoticeService.setNotice('Correo de recuperación enviado', 'success');
                    // this.router.navigateByUrl('/entrar');
                } else {
                    this.authNoticeService.setNotice('Éste correo no está asociado a ninguna cuenta', 'danger');
                }
            }),
            takeUntil(this.unsubscribe),
            finalize(() => {
                this.cargando = false;
                this.cdr.markForCheck();
            })
        ).subscribe();
    }

    cambiar() {
        const controls = this.changePasswordForm.controls;

        if (this.changePasswordForm.invalid) {
            Object.keys(controls).forEach(controlName =>
                controls[controlName].markAsTouched()
            );
            return;
        }

        this.cargando = true;

        const data = {
            user: this.rutaActiva.snapshot.params.usuario,
            password: controls.password.value,
            confirmPassword: controls.confirmPassword.value
        };

        this.authService.requestPasswordChange(data).pipe(
            tap(r => {
                if (!r.err) {
                    this.authNoticeService.setNotice('Contraseña restablecida', 'success');
                    this.router.navigateByUrl('/entrar');
                } else {
                    this.authNoticeService.setNotice('No se pudo restablecer su contraseña', 'danger');
                }
            }),
            takeUntil(this.unsubscribe),
            finalize(() => {
                this.cargando = false;
                this.cdr.markForCheck();
            })
        ).subscribe();
    }

    isControlHasError(controlName: string, validationType: string): boolean {
        const control = this[ this.Mode ? 'changePasswordForm':'forgotPasswordForm' ].controls[controlName];
        if (!control) {
            return false;
        }

        const result =
            control.hasError(validationType) &&
            (control.dirty || control.touched);
        return result;
    }
}
