export class MenuConfig {
	public natural: any = {
		header: {
			self: {},
			items: []
		},
		aside: {
			self: {},
			items: [
				{
					title: 'Inicio',
					root: true,
					icon: 'flaticon2-architecture-and-city',
					page: '/dashboard',
					translate: 'MENU.DASHBOARD',
					bullet: 'dot'
				},
				{
					title: 'Registrar',
					root: true,
					icon: 'flaticon2-architecture-and-city',
					page: '/register',
					translate: 'MENU.DASHBOARD',
					bullet: 'dot'
				},
				{
					title: 'Registro de Armas',
					root: true,
					bullet: 'dot',
					icon: 'flaticon2-browser-2',
					submenu: [
						{
							title: 'Armas',
							root: true,
							icon: 'fa fa-home',
							page: '/reg-armas',
							bullet: 'dot'
						},
						{
							title: 'Movilidad',
							root: true,
							icon: 'fa fa-car',
							page: '/reg-armas',
							bullet: 'dot'
						},
						{
							title: 'Causas',
							root: true,
							icon: 'fa fa-piggy-bank',
							page: '/reg-armas',
							bullet: 'dot'
						},
						{
							title: 'Perfil',
							root: true,
							icon: 'fa fa-id-card',
							page: '/perfil',
							bullet: 'dot'
						},
						
						{
							title: 'Asignacion',
							root: true,
							icon: 'fa fa-id-card',
							page: '/perfil',
							bullet: 'dot'
						}
					],
				},
				{
					title: 'Declaración y Liquidación',
					root: true,
					bullet: 'dot',
					icon: 'flaticon-notepad',
					submenu: [
						{
							title: 'Tasas Administrativas',
							root: true,
							icon: 'fas fa-money-bill',
							page: '/declaracion-liquidacion/tasas-administrativas',
							bullet: 'dot'
						},
						{
							title: 'Inmuebles',
							root: true,
							icon: 'fas fa-home',
							page: '/declaracion-liquidacion/inmuebles',
							bullet: 'dot'
						},
						{
							title: 'Vehículos',
							root: true,
							icon: 'fas fa-trash-alt',
							page: '/declaracion-liquidacion/vehiculos',
							bullet: 'dot'
						},
					],
				},
				{
					title: 'Recaudación',
					root: true,
					bullet: 'dot',
					icon: 'fas fa-money-check',
					submenu: [
						{
							title: 'Actividad Económica',
							root: true,
							icon: 'fas fa-money-bill',
							page: '/pagos/actividad-economica',
							bullet: 'dot'
						},
						{
							title: 'Inmuebles',
							root: true,
							icon: 'fas fa-money-bill',
							page: '/declaracion-liquidacion/inmuebles',
							bullet: 'dot'
						},
					],
				},
				{
					title: 'Cupones',
					root: true,
					icon: 'fa fa-gift',
					page: '/cupones',
					bullet: 'dot'
				},
				// {
				// 	title: 'Ordenanzas',
				// 	root: true,
				// 	icon: 'fa fa-graduation-cap',
				// 	page: '/ordenanzas',
				// 	bullet: 'dot'
				// }
			]
		},
	};
	public juridico: any = {
		header: {
			self: {},
			items: []
		},
		aside: {
			self: {},
			items: [
				{
					title: 'Inicio',
					root: true,
					icon: 'flaticon2-architecture-and-city',
					page: '/dashboard',
					translate: 'MENU.DASHBOARD',
					bullet: 'dot'
				},
				// {
				// 	title: 'Registrar',
				// 	root: true,
				// 	icon: 'flaticon2-architecture-and-city',
				// 	page: '/register',
				// 	// translate: 'MENU.DASHBOARD',
				// 	bullet: 'dot'
				// },
				{
					title: 'Datos Generales',
					root: true,
					bullet: 'dot',
					icon: 'fas fa-sign-in-alt',
					submenu: [
						{
							title: 'Director',
							root: true,
							icon: 'fa fa-user-check',
							page: '/propietario',
							bullet: 'dot'
						},
						{
							title: 'Repr Parque de Armas',
							root: true,
							icon: 'fa fa-user-tie',
							page: '/representante-legal',
							bullet: 'dot'
						},
						{
							title: 'Perfil',
							root: true,
							icon: 'fa fa-id-card',
							page: '/perfil',
							bullet: 'dot'
						},
						{
							title: 'Unidades o Dependecias',
							root: true,
							icon: 'fas fa-city',
							page: '/unidep',
							bullet: 'dot'
						},
						
					],
				},
				{
					title: 'Armas',
					root: true,
					bullet: 'dot',
					icon: 'fas fa-sign-in-alt',
					submenu: [
						{
							title: 'Registro de Armas',
							root: true,
							icon: 'fas fa-chart-bar',
							page: '/reg-armas',
							bullet: 'dot'
						},
						{
							title: 'Asignacion del Arma',
							root: true,
							icon: 'fa fa-id-card',
							page: '/asignacion-componentes',
							bullet: 'dot'
						},
						
						{
							title: 'Registro Balistico',
							root: true,
							icon: 'fa fa-id-card',
							page: '/regis-balis',
							bullet: 'dot'
						},
						{
							title: 'Porte de Armas',
							root: true,
							icon: 'fa fa-id-card',
							page: '/porte-armas',
							bullet: 'dot'
						},
						{
							title: 'Incautacion de Armas',
							root: true,
							icon: 'fa fa-id-card',
							page: '/encau-armas',
							bullet: 'dot'
						 
						} 
					],
				},
				{
					title: 'Municiones',
					root: true,
					bullet: 'dot',
					icon: 'fas fa-sign-in-alt',
					submenu: [
						{
							title: 'Registro de Municiones',
							root: true,
							icon: 'fas fa-chart-bar',
							page: '/reg-mun',
							bullet: 'dot'
						},
						// {
						// 	title: 'Tasas Administrativas',
						// 	root: true,
						// 	icon: 'fas fa-money-bill',
						// 	page: '/declaracion-liquidacion/tasas-administrativas',
						// 	bullet: 'dot'
						// },
						// {
						// 	title: 'Inmuebles',
						// 	root: true,
						// 	icon: 'fas fa-home',
						// 	page: '/declaracion-liquidacion/inmuebles',
						// 	bullet: 'dot'
						// },
						// {
						// 	title: 'Vehículos',
						// 	root: true,
						// 	icon: 'fas fa-truck',
						// 	page: '/declaracion-liquidacion/vehiculos',
						// 	bullet: 'dot'
						// },
						// {
						// 	title: 'Tasa de Bomberos',
						// 	root: true,
						// 	icon: 'fas fa-fire-extinguisher',
						// 	page: '/declaracion-liquidacion/tasa-bomberos',
						// 	bullet: 'dot'
						// },
						// {
						// 	title: 'Permisos',
						// 	root: true,
						// 	icon: 'fas fa-file-alt',
						// 	page: '/declaracion-liquidacion/permisos',
						// 	bullet: 'dot'
						// },
					],
				},
				
				{
					title: 'Explosivos',
					root: true,
					bullet: 'dot',
					icon: 'fas fa-sign-in-alt',
					submenu: [
					
						{
							title: 'Registro de Explosivos',
							root: true,
							icon: 'fas fa-chart-bar',
							page: '/reg-explo',
							bullet: 'dot'
						},
						
						// {
						// 	title: 'Actividades Económicas',
						// 	root: true,
						// 	icon: 'fas fa-chart-bar',
						// 	page: '/declaracion-liquidacion/actividad-economica',
						// 	bullet: 'dot'
						// },
						// {
						// 	title: 'Tasas Administrativas',
						// 	root: true,
						// 	icon: 'fas fa-money-bill',
						// 	page: '/declaracion-liquidacion/tasas-administrativas',
						// 	bullet: 'dot'
						// },
						// {
						// 	title: 'Inmuebles',
						// 	root: true,
						// 	icon: 'fas fa-home',
						// 	page: '/declaracion-liquidacion/inmuebles',
						// 	bullet: 'dot'
						// },
						// {
						// 	title: 'Vehículos',
						// 	root: true,
						// 	icon: 'fas fa-truck',
						// 	page: '/declaracion-liquidacion/vehiculos',
						// 	bullet: 'dot'
						// },
						// {
						// 	title: 'Tasa de Bomberos',
						// 	root: true,
						// 	icon: 'fas fa-fire-extinguisher',
						// 	page: '/declaracion-liquidacion/tasa-bomberos',
						// 	bullet: 'dot'
						// },
						// {
						// 	title: 'Permisos',
						// 	root: true,
						// 	icon: 'fas fa-file-alt',
						// 	page: '/declaracion-liquidacion/permisos',
						// 	bullet: 'dot'
						// },
					],
				},
				{
					title: 'Sistema de Arma',
					root: true,
					bullet: 'dot',
					icon: 'fas fa-sign-in-alt',
					submenu: [
					
						{
							title: 'Registro del Sistema',
							root: true,
							icon: 'fas fa-chart-bar',
							page: '/reg-explo',
							bullet: 'dot'
						},
						// {
						// 	title: 'Actividades Económicas',
						// 	root: true,
						// 	icon: 'fas fa-chart-bar',
						// 	page: '/declaracion-liquidacion/actividad-economica',
						// 	bullet: 'dot'
						// },
						// {
						// 	title: 'Tasas Administrativas',
						// 	root: true,
						// 	icon: 'fas fa-money-bill',
						// 	page: '/declaracion-liquidacion/tasas-administrativas',
						// 	bullet: 'dot'
						// },
						// {
						// 	title: 'Inmuebles',
						// 	root: true,
						// 	icon: 'fas fa-home',
						// 	page: '/declaracion-liquidacion/inmuebles',
						// 	bullet: 'dot'
						// },
						// {
						// 	title: 'Vehículos',
						// 	root: true,
						// 	icon: 'fas fa-truck',
						// 	page: '/declaracion-liquidacion/vehiculos',
						// 	bullet: 'dot'
						// },
						// {
						// 	title: 'Tasa de Bomberos',
						// 	root: true,
						// 	icon: 'fas fa-fire-extinguisher',
						// 	page: '/declaracion-liquidacion/tasa-bomberos',
						// 	bullet: 'dot'
						// },
						// {
						// 	title: 'Permisos',
						// 	root: true,
						// 	icon: 'fas fa-file-alt',
						// 	page: '/declaracion-liquidacion/permisos',
						// 	bullet: 'dot'
						// },
					],
				},
				// {
				// 	title: 'Sustancias Quimicas',
				// 	root: true,
				// 	bullet: 'dot',
				// 	icon: 'flaticon-notepad',
				// 	submenu: [
				// 		{
				// 			title: 'Registro de Quimicos',
				// 			root: true,
				// 			icon: 'fas fa-chart-bar',
				// 			page: '/reg-quim',
				// 			bullet: 'dot'
				// 		},
				// 		// {
				// 		// 	title: 'nuevo',
				// 		// 	root: true,
				// 		// 	icon: 'fas fa-chart-bar',
				// 		// 	page: '/actividad-economica',
				// 		// 	bullet: 'dot'
				// 		// },
				// 		// {
				// 		// 	title: 'Explosivos',
				// 		// 	root: true,
				// 		// 	icon: 'fas fa-chart-bar',
				// 		// 	page: '/reg-explo',
				// 		// 	bullet: 'dot'
				// 		// },
				// 		{
				// 			title: 'Actividades Económicas',
				// 			root: true,
				// 			icon: 'fas fa-chart-bar',
				// 			page: '/user-management/users',
				// 			bullet: 'dot'
				// 		},
				// 		{
				// 			title: 'Tasas Administrativas',
				// 			root: true,
				// 			icon: 'fas fa-money-bill',
				// 			page: '/declaracion-liquidacion/tasas-administrativas',
				// 			bullet: 'dot'
				// 		},
						// {
						// 	title: 'Inmuebles',
						// 	root: true,
						// 	icon: 'fas fa-home',
						// 	page: '/declaracion-liquidacion/inmuebles',
						// 	bullet: 'dot'
						// },
						// {
						// 	title: 'Vehículos',
						// 	root: true,
						// 	icon: 'fas fa-truck',
						// 	page: '/declaracion-liquidacion/vehiculos',
						// 	bullet: 'dot'
						// },
						// {
						// 	title: 'Tasa de Bomberos',
						// 	root: true,
						// 	icon: 'fas fa-fire-extinguisher',
						// 	page: '/declaracion-liquidacion/tasa-bomberos',
						// 	bullet: 'dot'
						// },
						// {
						// 	title: 'Permisos',
						// 	root: true,
						// 	icon: 'fas fa-file-alt',
						// 	page: '/declaracion-liquidacion/permisos',
						// 	bullet: 'dot'
						// },
					// ],
				// },
				{
					title: 'Comp mov Material',
					root: true,
					bullet: 'dot',
					icon: 'fas fa-sign-in-alt',
					submenu: [
						{
							title: 'Mov Material Clase III-M',
							root: true,
							icon: 'fas fa-file-alt',
							page: '/movimiento-materia',
							bullet: 'dot'
						},
						{
							title: 'Mov Material Clase V-M',
							root: true,
							icon: 'fas fa-file-alt',
							page: '/movimiento-materia',
							bullet: 'dot'
						},
						{
							title: 'Mov Material Clase VII-W',
							root: true,
							icon: 'fas fa-file-alt',
							page: '/movimiento-materia',
							bullet: 'dot'
						},
						{
							title: 'Mov Material Clase IX-M',
							root: true,
							icon: 'fas fa-file-alt',
							page: '/movimiento-materia',
							bullet: 'dot'
						},
						
						//,
						// {
						// 	title: 'nuevo',
						// 	root: true,
						// 	icon: 'fa fa-id-card',
						// 	page: '/conciliado',
						// 	bullet: 'dot'
						// }
						// {
						// 	title: 'Perfil',
						// 	root: true,
						// 	icon: 'fa fa-id-card',
						// 	page: '/perfil',
						// 	bullet: 'dot'
						// },
						
					],
				},
				{
					title: 'Reportes',
					root: true,
					bullet: 'dot',
					icon: 'fas fa-sign-in-alt',
					submenu: [
						{
							title: 'Reporte de armas',
							root: true,
							icon: 'fas fa-sort-alpha-up-alt',
							page: '/estadistica-mensual',
							bullet: 'dot'
						},
						
						// {
						// 	title: 'Inmuebles',
						// 	root: true,
						// 	icon: 'fas fa-money-bill',
						// 	page: '/declaracion-liquidacion/inmuebles',
						// 	bullet: 'dot'
						// },
					],
				},
				{
					title: 'Registrar',
					root: true,
					icon: 'fas fa-sign-in-alt',
					page: '/registra',
					bullet: 'dot'
				},
				// {
				// 	title: 'Ordenanzas',
				// 	root: true,
				// 	icon: 'fa fa-graduation-cap',
				// 	page: '/ordenanzas',
				// 	bullet: 'dot'
				// }
			]
		},
	};

	public type;

	constructor(type: string){
		this.type = type;
	}

	public get configs(): any {
		if (this.type === 'N') {
			return this.natural;
		} else {
			return this.juridico;
		}
	}
}
