import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable} from 'rxjs';
import { map } from 'rxjs/operators';
// import { ListaSelect } from '../_models/ListaSelect.model';

// const httpHeaders = new HttpHeaders();
// httpHeaders.set('Content-Type', 'application/json');
const httpHeaders = {'Content-Type': 'text/plain'};

@Injectable({
    providedIn: 'root'
})

export class ConsultasService {

    constructor(private http: HttpClient) {}

   // Éste método debe devolver un objeto de tipo ListaSelect
   listadoSelect(ruta: string, param?: any): Observable<any> {
       ruta = param !== undefined ? environment.API_URL + 'consultar/' + ruta + '?q='+param : environment.API_URL + 'consultar/' + ruta ;
       // console.log(ruta);
        return this.http.get<any>(ruta, { headers: httpHeaders}).pipe(map( data => data['dat'] ));
   }

   // Éste método debe devolver un objeto de tipo Boolean
   existe(ruta: string, param: string): Observable<boolean> {
        return this.http.get<boolean>(environment.API_URL + 'consultar/' + ruta + '?q=' + param, { headers: httpHeaders})
                    .pipe(map( data => {
                        return data['err'] ? false:data['val'];
                    }));
   }
}